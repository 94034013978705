import {
	usePostOfficeContext,
	usePostOfficeRoute,
	useStableObject,
} from '@atlassian/post-office-context';
import {
	PerformanceTracking,
	StableProviders,
	withErrorBoundary,
} from '@post-office/placement-contexts';

import {
	type ErrorBoundariedPropsV2,
	type PlacementComponentPropsInternalV2,
	type PlacementComponentV2,
} from './types';

export const ERROR_BOUNDARY_LOCATION = 'create-placement-component';

export const createBasePlacementComponentV2 = <P,>(
	placementId: string,
	Component: PlacementComponentV2<P>,
) =>
	withErrorBoundary<PlacementComponentPropsInternalV2<P>>((props) => {
		const { onEvent, ...componentProps } = props;

		const context = usePostOfficeContext();
		const route = usePostOfficeRoute();

		const stableContext = useStableObject(context);

		return (
			<PerformanceTracking initialData={undefined} onEvent={onEvent}>
				<StableProviders route={route} context={stableContext} placement={{ placementId }}>
					{/** @ts-expect-error: TS-2322 Typescript wants you to pass in other props to Component as well as componentProps  */}
					<Component {...(componentProps as unknown as P)} />
				</StableProviders>
			</PerformanceTracking>
		);
	});

export const createPlacementComponentV2 = <P,>(
	placementId: string,
	Component: PlacementComponentV2<P>,
) => {
	const placement = createBasePlacementComponentV2(placementId, Component);

	return (props: ErrorBoundariedPropsV2<P>) => {
		return placement({
			...props,
			fallbackRender: () => null,
		});
	};
};
