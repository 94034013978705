import {
	type FetchCallback,
	PlacementFetchingProvider,
} from '@atlassian/post-office-placement-data';
import { type ArityOneParameter } from '@post-office/shared-contracts/pipeline/contraints';
import React from 'react';

import { type AnyPlacementRendererStage } from '../../create-placement/types';

export const createPlacementTestComponent = <T extends AnyPlacementRendererStage>({
	Placement,
	port,
}: {
	Placement: T;
	port?: number;
}) => {
	const PORT = port ?? 4224;

	const API = new URL('', `http://localhost:${PORT}`);

	const testFetch: FetchCallback = (url, ...args) => global.fetch(new URL(url, API), ...args);

	return ({ children, ...props }: ArityOneParameter<T>) => (
		<PlacementFetchingProvider fetch={testFetch}>
			{React.createElement(Placement, props, children)}
		</PlacementFetchingProvider>
	);
};
