import { ConfluenceNbaBannerMessage } from '@post-office/placements--growth/components/confluence-side-nav';
import { useIntl } from 'react-intl-next';

export const ConfluenceCreateFirstPage = () => {
	const intl = useIntl();
	const headerText = intl.formatMessage({
		id: 'post-office.confluence-side-nav.next-best-action.create-page-header',
		defaultMessage: 'Plan projects, take notes, and track tasks.',
		description: 'Header text for confluence side nav next best action banner',
	});

	const ctaMessage = intl.formatMessage({
		id: 'post-office.confluence-side-nav.next-best-action.create-page-cta-message',
		defaultMessage: 'Create page',
		description: 'Call to action text for confluence side nav next best action banner',
	});

	const ctaActionHref = () => {
		// TODO: PO-4069 some dummy href for create page
		return 'create-page-href';
	};

	const NBA = {
		icon: (
			<div>
				<svg
					width="40"
					height="40"
					viewBox="0 0 40 40"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M27.0876 12.9458H10.0293V30H27.0835V12.9458H27.0876ZM13.6876 18.4291H23.4335V16.6H13.6876V18.4291ZM13.6876 22.3875H23.4335V20.5583H13.6876V22.3875ZM19.7793 26.3458H13.6876V24.5166H19.7793V26.3458Z"
						fill="#BF63F3"
					/>
					<path
						d="M23.7292 18.954L20 19.9999L21.0458 16.2707L30.3458 6.9707L33.0292 9.65404L23.7292 18.954Z"
						fill="#1868DB"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M24.3708 12.9458L21.0458 16.2708L20.9542 16.6H23.4333V18.4291H20.4417L20 20L23.7292 18.9541L27.0875 15.5958V12.9458H24.3708Z"
						fill="#09326C"
					/>
				</svg>
			</div>
		),
		header: headerText,
		ctaMessage: ctaMessage,
	};
	return (
		<ConfluenceNbaBannerMessage
			icon={NBA.icon}
			header={NBA.header}
			ctaMessage={NBA.ctaMessage}
			onMessageClick={ctaActionHref}
		/>
	);
};
