import React, { useMemo } from 'react';
import { TestPlacement } from '@post-office/placements--growth/confluence-side-nav/test';
import { Placement as ConfluenceSideNav } from '@post-office/placements/confluence-side-nav';
import {
	PlacementFetchProvider,
	usePlacementPreloadedData,
} from '@atlassian/confluence-post-office-provider/entry-points/preload-placement';
import { Placement } from '@atlassian/post-office-placement';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { useIntl } from 'react-intl-next';

import { fg } from '@confluence/feature-gating';
import { expValEquals } from '@confluence/feature-experiments';

export const PostOfficeConfluenceSideNavPlacement = ({
	localDev = false,
}: {
	localDev?: boolean;
}) => {
	const preloadedInitialData = usePlacementPreloadedData('confluence-side-nav');
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();

	const memoizedPlacementProps = useMemo(
		() => ({
			preloadedInitialData,
		}),
		[preloadedInitialData],
	);
	const { locale } = useIntl();
	const isEnglishLocale = locale.toLowerCase().startsWith('en');

	if (!fg('post-office-connie-side-nav-killswitch')) {
		return <></>;
	}

	if (localDev) {
		return <TestPlacement />;
	}

	if (
		isEnglishLocale &&
		expValEquals('post-office_expt_confluence-next-best-actions', 'cohort', 'variation')
	) {
		return (
			<div
				data-testid="confluence-side-nav-placement"
				data-vc="confluence-side-nav-placement"
				{...ssrPlaceholderIdProp}
			>
				<PlacementFetchProvider>
					<Placement
						placementId="confluence-side-nav"
						// @ts-ignore - Type 'ComponentType<Partial<ErrorBoundaryPropsWithRender>> is not assignable to type 'PlacementComponent<LocalPlacementProps>'
						PlacementComponent={ConfluenceSideNav}
						placementProps={memoizedPlacementProps}
						disableInitialData={Boolean(preloadedInitialData)}
					/>
				</PlacementFetchProvider>
			</div>
		);
	}

	return <></>;
};
