import { type BackendMessageStageInput } from '@post-office/placement-common-v2';
import {
	PlacementBackend,
	PlacementFrontend,
} from '@post-office/placements--growth/confluence-side-nav';

import { ConfluenceCreateASpaceMessage } from './messages/confluence-create-a-space';
import { ConfluenceCreateFirstPage } from './messages/confluence-create-first-page';
import { ConfluenceCreateFirstWhiteboard } from './messages/confluence-create-first-whiteboard';
import { type MessageTemplateContext } from '../../..';
import { assertMessageTemplateContext } from '../../../__generated__/validation/messageTemplateContext';

export const MessageBackend = PlacementBackend.createMessageBackend(() => ({
	transformMetadata: {
		validate: assertMessageTemplateContext,
		stage: () => ({
			goalMetadata: {
				primaryGoal: {
					goalId: 'clickthrough',
				},
			},
		}),
	},
	hydrate: {
		validate: assertMessageTemplateContext,
		stage: ({
			message,
		}: BackendMessageStageInput<MessageTemplateContext>): Promise<MessageTemplateContext> => {
			return Promise.resolve(message.context);
		},
	},
}));

export const MessageFrontend = PlacementFrontend.createMessageFrontend({
	render: {
		validate: assertMessageTemplateContext,
		component: ({ nextBestActionType }: MessageTemplateContext) => {
			switch (nextBestActionType) {
				case 'CREATE_SPACE':
					return <ConfluenceCreateASpaceMessage />;
				case 'CREATE_PAGE':
					return <ConfluenceCreateFirstPage />;
				case 'CREATE_WHITEBOARD':
					return <ConfluenceCreateFirstWhiteboard />;
				default:
					return <></>;
			}
		},
	},
});
