import { defaultMessageBackend, noValidation } from '@post-office/placement-common-v2';

import { PlacementBackend, PlacementFrontend } from '../../index';

type MessageTemplateContext = {
	nextBestActionType: 'CREATE_SPACE' | 'CREATE_PAGE' | 'CREATE_WHITEBOARD';
};

export const MessageBackend = PlacementBackend.createMessageBackend(defaultMessageBackend);

export const MessageFrontend = PlacementFrontend.createMessageFrontend({
	render: {
		validate: noValidation,
		component: (_props: MessageTemplateContext) => (
			<>
				<div
					style={{
						backgroundColor: 'lightblue',
						border: '1px solid black',
						padding: '20px',
						borderRadius: '5px',
						minWidth: 'fit-content',
						// width: 'fit-content',
						textAlign: 'center',
						marginLeft: '10px',
						marginRight: '10px',
						marginTop: '10px',
					}}
				>
					{/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
					<h2>Create a page</h2>
				</div>
			</>
		),
	},
});
