import { createPlacementTestComponent } from '@post-office/placement-common-v2/placement-test-component';

import { PlacementBackend, PlacementFrontend } from '..';
import {
	MessageBackend as createMessageBackendConnieTestMessage,
	MessageFrontend as createMessageFrontendConnieTestMessage,
} from '../test-messages/connie-test-message';

const messageBackends = [
	createMessageBackendConnieTestMessage({ messageTemplateId: 'connie-test-message' }),
];

const messageFrontends = [
	createMessageFrontendConnieTestMessage({ messageTemplateId: 'connie-test-message' }),
];

export const placementBackendFactory = PlacementBackend.createPlacementBackend({
	messageTemplates: messageBackends,
});

export const Placement = PlacementFrontend.createPlacementFrontendComponent({
	messageTemplates: messageFrontends,
});

export const TestPlacement = createPlacementTestComponent({ Placement });
