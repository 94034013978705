// integrated-teams/{team-name}/placement-v2/src/{placement-id}/index.tsx`
import { type DefaultMessagesResponse, createPlacement } from '@post-office/placement-common-v2';
import { usePlacementApi } from '@post-office/use-placement-api';

const { createFrontend, createBackend } = createPlacement({
	placementId: 'confluence-side-nav',
});

export const PlacementBackend = createBackend();

export const PlacementFrontend = createFrontend((Messages) => () => {
	const { data, error, isLoading } = usePlacementApi<DefaultMessagesResponse>('/');

	if (error) {
		return null;
	}

	return (
		<div
			style={{
				marginBottom: '10px',
				maxWidth: '296px',
			}}
		>
			{/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
			{!isLoading &&
				[data.messages[0]].map((props) => {
					return <Messages.render {...props} key={props?.messageInstanceId} />;
				})}
		</div>
	);
});
