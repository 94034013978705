import React, { useContext, useRef, useState, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { ModalTransition } from '@atlaskit/modal-dialog';

import { Help } from '@atlassian/navigation-system/top-nav';

import { useRightSidebarContext } from '@confluence/page-layout-context';
import {
	HELP_PANEL_ID,
	HELP_PANEL_WIDTH,
	getRouteData,
	useInProductHelpActions,
} from '@confluence/in-product-help';
import { START_TOUCH } from '@confluence/navdex';
import {
	ExperienceTrackerContext,
	VIEW_IN_PRODUCT_HELP_EXPERIENCE,
} from '@confluence/experience-tracker';
import { useRouteDataRef } from '@confluence/route-manager';
import { SSRMouseEventWrapper, SSR_NAV_HELP_METRIC } from '@confluence/browser-metrics';
import {
	GeneralShortcutListener,
	HELP_SHORTCUT,
	HELP_SHORTCUT_IN_EDIT,
	SUBMIT_FEEDBACK_SHORTCUT,
} from '@confluence/shortcuts';
import { FeedbackCollector } from '@confluence/feedback-collector';
import { KeyboardShortcutsDialog } from '@confluence/keyboard-shortcuts-dialog';
import { preloadHelpMenu } from '@confluence/nav-menus/entry-points/preloadHelpMenu';
import { LoadableLazy } from '@confluence/loadable';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

const HelpMenuLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-confluencenav-menusentry-pointsHelpMenu" */ '@confluence/nav-menus/entry-points/HelpMenu'
			)
		).HelpMenu,
});

export const HelpButton = () => {
	const triggerButtonRef = useRef<HTMLButtonElement | null>(null);
	const rightSidebarContext = useRightSidebarContext();
	const inProductHelp = useInProductHelpActions();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const routeData = useRouteDataRef();
	const intl = useIntl();
	const isHelpPanelOpen = rightSidebarContext.getCurrent()?.panelId === HELP_PANEL_ID;
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();
	const isOnEditRoute = useIsEditorPage();

	const [activeDialog, setActiveDialog] = useState(null);

	const onClick = () => {
		if (isHelpPanelOpen) {
			rightSidebarContext.close(HELP_PANEL_ID);
		} else {
			experienceTracker.start({
				name: VIEW_IN_PRODUCT_HELP_EXPERIENCE,
				attributes: {
					navVersion: '4',
				},
			});

			rightSidebarContext.open(HELP_PANEL_ID, HELP_PANEL_WIDTH);
			rightSidebarContext.setTriggerButtonRef?.(triggerButtonRef);

			const { routeName, routeGroup } = getRouteData(routeData.current?.href || '');
			inProductHelp.setRouteName(routeName);
			inProductHelp.setRouteGroup(routeGroup);
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'globalNavigation',
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'help',
				attributes: {
					selectedItemPageContext: routeData.current?.routeName,
					navigationLayer: 'global',
					navigationContainer: 'top',
					navVersion: '4',
					navdexPointType: START_TOUCH,
				},
			},
		}).fire();
	};

	const closeDialog = useCallback(() => {
		setActiveDialog(null);
	}, [setActiveDialog]);

	const openDialog = useCallback(
		(dialog: any) => {
			setActiveDialog(dialog(closeDialog));
		},
		[setActiveDialog, closeDialog],
	);

	const openFeedbackCollector = () =>
		openDialog((onClose) => <FeedbackCollector onClose={onClose} />);
	const openShortcutsDialog = () =>
		openDialog((onClose) => <KeyboardShortcutsDialog onClose={onClose} />);

	const preloadMenu = () => {
		void preloadHelpMenu();
		void HelpMenuLoader.preload();
	};

	return (
		<>
			<SSRMouseEventWrapper metricName={SSR_NAV_HELP_METRIC}>
				{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
				<div onMouseEnter={preloadMenu}>
					<Help
						onClick={onClick}
						label={intl.formatMessage(i18n.help)}
						isSelected={isHelpPanelOpen}
						ref={triggerButtonRef}
					/>
				</div>
			</SSRMouseEventWrapper>
			<GeneralShortcutListener
				accelerator={isLiveEditMode || isOnEditRoute ? HELP_SHORTCUT_IN_EDIT : HELP_SHORTCUT}
				listener={openShortcutsDialog}
				isAppNavigationShortcut
			/>
			{!isLiveEditMode && !isOnEditRoute && (
				<GeneralShortcutListener
					accelerator={SUBMIT_FEEDBACK_SHORTCUT}
					listener={openFeedbackCollector}
					isAppNavigationShortcut
				/>
			)}
			<ModalTransition>{activeDialog}</ModalTransition>
		</>
	);
};

const i18n = defineMessages({
	help: {
		id: 'app-navigation.top-navigation.help',
		defaultMessage: 'Help',
		description: 'Label for the top navigation help button',
	},
});
